import {createTheme} from "@mui/material/styles";
import {grey} from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#375af7",
      light: "#7c87ff",
      dark: "#0031c3",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fcf351",
      light: "#ffff85",
      dark: "#c6c110",
      contrastText: "#000000",
    },
    common: {
      black: "#111111",
      white: "#ffffff",
    },
    grey: grey,

    success: {main: "#52C41A"},
  },

  typography: {
    fontFamily: `'Inter', Helvetica, system-ui, Arial, Lucida, sans-serif`,

    body1: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    h1: {
      fontSize: "24px",
      fontWeight: "600",
      lineHeight: "32px",
    },
    h2: {
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "32px",
    },
    h3: {
      fontSize: "20px",
      fontWeight: "600",
      lineHeight: "28px",
    },
    h4: {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "24px",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "20px",
      color: grey[600],
    },
    button: {
      textTransform: "none",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1000,
      xl: 1400,
    },
  },
});
