import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {keyframes} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ArrowSVG} from "components/svg/arrow";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";

export function InstagramPopup(props: {onClose: () => void}) {
  const {t} = useTranslation("common");

  return (
    <Paper elevation={3}>
      <Stack spacing={1} sx={{p: 1, backgroundColor: "primary.main"}}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
          pr={1}
        >
          <Typography variant="body2" color="white" fontWeight={500}>
            <Trans
              i18nKey="common:ig-popup-text"
              defaultTrans="Hey <br/> Wow"
              components={{
                br: <br />,
              }}
            />
          </Typography>
          <Box pt={2}>
            <Box sx={{animation: `${arrowKeyframes} 1s infinite`}}>
              <ArrowSVG width="24px" />
            </Box>
          </Box>
        </Stack>
        <Button
          sx={{alignSelf: "flex-end", background: "rgba(255, 255, 255, 0.1)"}}
          variant="contained"
          color="primary"
          onClick={props.onClose}
        >
          {t("ig-popup-action")}
        </Button>
      </Stack>
    </Paper>
  );
}

const arrowKeyframes = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10%, -10%);
  }
  100% {
    transform: translate(0, 0);
  }
`;
