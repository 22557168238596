export function ArrowSVG(props: {width: string}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 48"
      width={props.width}
    >
      <filter
        id="a"
        width="19.4"
        height="47.9"
        x=".1"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_156371_158672"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_dropShadow_156371_158672"
          result="effect2_dropShadow_156371_158672"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_156371_158672"
          result="shape"
        />
      </filter>
      <g filter="url(#a)">
        <path
          fill="#fff"
          d="M17.5 0 8.6 7.4l10.9 4zm-4 8.1c-8 21.7-4.9 13-13.4 35l1.8.8c8.6-22 5.4-13.4 13.4-35.1z"
        />
      </g>
    </svg>
  );
}
