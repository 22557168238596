/* eslint-disable @next/next/no-img-element */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {InstagramPopup} from "components/ig-popup";
import {Nav} from "components/nav";
import {sx} from "feat/styles/sx";
import {isInstagramUA} from "feat/utils/user-agent";
import Link from "next/link";
import {useEffect, useReducer, useState, Suspense} from "react";
import {GTMAnalytics} from "./analytics";

const appShellSx = sx({
  minHeight: "100%",
  width: "100%",
  maxWidth: "100%",
});

function useIsInstagram() {
  const [ig, setIg] = useState(false);
  const [closed, close] = useReducer(() => true, false);
  useEffect(() => {
    if (window?.navigator?.userAgent) {
      setIg(isInstagramUA(navigator.userAgent));
    }
  }, []);
  return [closed ? false : ig, close] as const;
}

export default function BaseLayout({children}: {children: React.ReactNode}) {
  const [showIgPopup, closeIgPopup] = useIsInstagram();

  return (
    <Stack sx={appShellSx}>
      <Suspense>
        <GTMAnalytics />
      </Suspense>

      {showIgPopup && (
        <Box
          sx={{
            position: "fixed",
            top: "16px",
            left: "8px",
            right: "8px",
            zIndex: "1200",
          }}
        >
          <InstagramPopup onClose={closeIgPopup} />
        </Box>
      )}

      <Header />

      <Box
        component="main"
        sx={{flexGrow: 1, maxWidth: "100%", position: "relative"}}
      >
        <Container maxWidth="xl" sx={{maxWidth: "100%"}}>
          {children}
        </Container>
      </Box>

      <Footer />
    </Stack>
  );
}

const appBarSx = sx({
  background: "white",
  color: "black",
  maxWidth: "100%",
  overflow: "auto",
  paddingY: 1,
});

function Header() {
  return (
    <AppBar position="sticky" elevation={1} sx={appBarSx}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo />
          <Nav />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function Logo() {
  return (
    <Box sx={{flexGrow: 1}}>
      <Link href="https://rescuenow.com.ua/" passHref>
        <a style={{cursor: "pointer"}}>
          <img
            alt="RescueNow"
            height="36px"
            src="https://rescuenow.com.ua/wp-content/uploads/2023/03/LogoRN.png"
            width="auto"
          />
        </a>
      </Link>
    </Box>
  );
}

function Footer() {
  return (
    <Box component="footer" sx={{background: "black", py: 3}}>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          sx={{color: "white", justifyContent: "space-between"}}
        >
          <Typography color="gray">
            © {new Date().getFullYear()} RescueNow. All rights reserved
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
