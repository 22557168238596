
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import {CacheProvider, EmotionCache} from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {ThemeProvider} from "@mui/material/styles";
import {createEmotionCache} from "feat/styles/create-emotion-cache";
import {theme} from "feat/styles/theme";
import {AppProps} from "next/app";
import Head from "next/head";
import AnalyticScripts from "../components/scripts";
import Layout from "../components/layout";
import * as fbq from "lib/fpixel";
import {useRouter} from "next/router";
import {useEffect} from "react";
import "intl-pluralrules";

const clientSideEmotionCache = createEmotionCache();

interface RescueNowAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const __Page_Next_Translate__ = function RescueNowApp(props: RescueNowAppProps) {
  const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;

  const router = useRouter();
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <CacheProvider value={emotionCache}>
      <AnalyticScripts />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="shortcut icon" href="/favicon-192x192.png" />
        <noscript>
          <img
            height="1"
            width="1"
            style={{display: "none"}}
            src={`https://www.facebook.com/tr?id=${fbq.FB_PIXEL_ID}&ev=PageView&noscript=1`}
          />
        </noscript>
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            html: {height: "100%"},
            body: {
              backgroundColor: "#f7f7f7",
              height: "100%",
              minHeight: "100%",
              maxWidth: "100vw",
            },
            "#__next": {height: "100%", maxWidth: "100vw"},
          }}
        />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </CacheProvider>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  